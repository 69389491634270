import Vue from 'vue'
import io from 'socket.io-client'

const logEvent = (message, data) => {
    if (window.location.href.includes('debug')) {
        console.log(message, data)
    }
}

export default {
    data() {
        return {
            isSocketConnected: false,
            unsubscribeFromBaseUpdates: null,
            unsubscribeFromSharingUpdates: null,
        }
    },
    mounted() {
        Vue.prototype.$socket = io(process.env.VUE_APP_WEBSOCKET_URL)
            .on('connect', () => {
                this.isSocketConnected = true
            })
            .on('debug', data => {
                logEvent('Socket debug message:', data)
            })
            .on('disconnect', () => {
                this.isSocketConnected = false
            })

        this.$watch(
            () => [
                this.isSocketConnected,
                this.$store.state.map.liveUpdatesEnabled,
                this.$store.state.auth.jwt?.access,
                this.$route.params.token,
            ],
            (
                [
                    isSocketConnected,
                    isLiveUpdatesEnabled,
                    newBaseToken,
                    newSharingToken,
                ],
                [
                    wasSocketConnected,
                    wasLiveUpdatesEnabled,
                    oldBaseToken,
                    oldSharingToken,
                ]
            ) => {
                if (!isSocketConnected || !isLiveUpdatesEnabled) {
                    this.unsubscribeFromBaseUpdates?.()
                    this.unsubscribeFromSharingUpdates?.()
                    return
                }

                if (newBaseToken !== oldBaseToken) {
                    this.unsubscribeFromBaseUpdates?.()
                }

                if (
                    newBaseToken &&
                    (newBaseToken !== oldBaseToken ||
                        !wasSocketConnected ||
                        !wasLiveUpdatesEnabled)
                ) {
                    this.subscribeToBaseUpdates(newBaseToken)
                }

                if (newSharingToken !== oldSharingToken) {
                    this.unsubscribeFromSharingUpdates?.()
                }

                if (
                    newSharingToken &&
                    (newSharingToken !== oldSharingToken ||
                        !wasSocketConnected ||
                        !wasLiveUpdatesEnabled)
                ) {
                    this.subscribeToSharingUpdates(newSharingToken)
                }
            }
        )
    },
    methods: {
        subscribeToBaseUpdates(token) {
            this.$socket.emit('authenticate', { token })

            const subscriptions = {
                keepalive: data => {
                    logEvent('Received keepalive update', data)
                    this.$store.commit('tracker/updateTrackerLastContact', {
                        id: data.tracker,
                        time: data.timestamp,
                        alert: data.alert,
                    })
                },
                position: data => {
                    logEvent('Received position update', data)
                    this.$store.commit('tracker/updateTrackerPosition', {
                        id: data.tracker,
                        lat: data.lat,
                        lng: data.long,
                        currentLocations: data.current_locations,
                        isOutside: data.is_outside,
                        time: data.timestamp,
                    })
                },
                sensor_data: data => {
                    logEvent('Received sensor data update', data)
                    this.$store.commit('tracker/updateTrackerSensorData', {
                        id: data.tracker,
                        values: data.values,
                        time: data.timestamp,
                    })
                },
            }

            Object.keys(subscriptions).forEach(key => {
                this.$socket.on(key, subscriptions[key])
            })

            this.unsubscribeFromBaseUpdates = () => {
                this.unsubscribeFromBaseUpdates = null
                Object.keys(subscriptions).forEach(key => {
                    this.$socket.off(key, subscriptions[key])
                })
            }
        },
        subscribeToSharingUpdates(token) {
            this.$socket.emit('authenticate', { token })

            const subscriptions = {
                keepalive: data => {
                    logEvent('Received keepalive update', data)
                    this.$store.commit('sharing/updateTrackerLastContact', {
                        id: data.asset,
                        time: data.timestamp,
                    })
                },
                position: data => {
                    logEvent('Received position update', data)
                    this.$store.commit('sharing/updateTrackerPosition', {
                        id: data.asset,
                        lat: data.lat,
                        lng: data.long,
                        time: data.timestamp,
                    })
                },
                sensor_data: data => {
                    logEvent('Received sensor data update', data)
                    this.$store.commit('sharing/updateTrackerSensorData', {
                        id: data.asset,
                        values: data.values,
                        time: data.timestamp,
                    })
                },
            }

            Object.keys(subscriptions).forEach(key => {
                this.$socket.on(key, subscriptions[key])
            })

            this.unsubscribeFromSharingUpdates = () => {
                this.unsubscribeFromSharingUpdates = null
                Object.keys(subscriptions).forEach(key => {
                    this.$socket.off(key, subscriptions[key])
                })
            }
        },
    },
}
