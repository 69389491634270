export default {
    downloadDataUrl,
    isElementVisible,
}

function downloadDataUrl(dataUrl, filename) {
    const linkElement = document.createElement('a')
    linkElement.setAttribute('href', dataUrl)
    linkElement.setAttribute('download', filename)
    document.body.appendChild(linkElement)
    linkElement.click()
    document.body.removeChild(linkElement)
}

// returns true if element is visible for user
// see https://gomakethings.com/how-to-test-if-an-element-is-in-the-viewport-with-vanilla-javascript/
function isElementVisible(element) {
    var bounding = element.getBoundingClientRect()
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    )
}
