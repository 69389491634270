<template>
    <div class="map-controls" :class="{ 'map-controls--mobile': mobile }">
        <!-- TEMP: -->
        <div />

        <div>
            <button
                :class="{ active: positionTrackingEnabled }"
                @click="toggleLocationTracking"
            >
                <LocationTargetIcon :width="iconSize" :height="iconSize" />
            </button>

            <MapSearch v-if="!mobile" />
        </div>

        <div>
            <button @click="toggleDropdown">
                <SettingsSliderIcon :width="iconSize" :height="iconSize" />

                <transition name="dropdown">
                    <div
                        v-if="isDropdownVisible"
                        class="map-controls__dropdown"
                        @click="$event.stopPropagation()"
                    >
                        <section>
                            <h3 class="t-title">
                                {{ $t('displayOptions') }}
                            </h3>

                            <div class="l-stack l-gap-1">
                                <template v-for="(control, key) in controls">
                                    <label
                                        v-if="!disabledControls[key]"
                                        :key="key"
                                        class="l-inline l-center-v l-gap-1"
                                    >
                                        <ToggleButton
                                            redesigned
                                            :value="control.value"
                                            @input="control.action"
                                        />

                                        <span class="t-truncated">
                                            {{ control.label }}
                                        </span>
                                    </label>
                                </template>
                            </div>
                        </section>

                        <section v-if="hasTachographAccess">
                            <h3 class="t-title">
                                {{ $t('labelOptions') }}
                            </h3>

                            <div class="l-stack l-gap-1">
                                <template
                                    v-for="(control, key) in labelControls"
                                >
                                    <label
                                        v-if="!disabledControls[key]"
                                        :key="key"
                                        class="l-inline l-center-v l-gap-1"
                                    >
                                        <ToggleButton
                                            redesigned
                                            :value="control.value"
                                            @input="control.action"
                                        />

                                        <span class="t-truncated">
                                            {{ control.label }}
                                        </span>
                                    </label>
                                </template>
                            </div>
                        </section>

                        <section>
                            <h3 class="t-title">
                                {{ $t('layerOptions') }}
                            </h3>

                            <div class="l-stack l-gap-1">
                                <label
                                    v-for="provider in tileProviders"
                                    :key="provider.id"
                                    class="l-inline l-center-v l-gap-1"
                                >
                                    <VRadio
                                        :checked="
                                            provider.id === activeLayer.id
                                        "
                                        :value="provider.id"
                                        @input="
                                            handleTileProviderChange(provider)
                                        "
                                    />

                                    <span class="t-truncated">
                                        {{ $t(`layerOptions-${provider.id}`) }}
                                    </span>
                                </label>
                            </div>
                        </section>
                    </div>
                </transition>
            </button>

            <div v-if="!mobile" class="button-group">
                <button
                    :disabled="!mapInstance || isZoomOutDisabled"
                    @click="mapInstance.zoomOut()"
                >
                    –
                </button>

                <button
                    :disabled="!mapInstance || isZoomInDisabled"
                    @click="mapInstance.zoomIn()"
                >
                    +
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { Radio } from 'vue-checkbox-radio'

import LocationTargetIcon from '../icons/LocationTargetIcon'
import MapSearch from './MapSearch'
import SettingsSliderIcon from '../icons/SettingsSliderFramedIcon'
import ToggleButton from '../ToggleButton'

export default {
    name: 'MapControls',
    components: {
        LocationTargetIcon,
        MapSearch,
        SettingsSliderIcon,
        ToggleButton,
        VRadio: Radio,
    },
    props: {
        disabledControls: {
            type: Object,
            default: () => ({}),
        },
        mobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dropdownHidingListener: null,
            isDropdownVisible: false,
        }
    },
    computed: {
        ...mapState('map', [
            'clusteringEnabled',
            'displayDriverName',
            'displayRemainingTime',
            'liveUpdatesEnabled',
            'mapInstance',
            'positionTrackingEnabled',
            'showAccuracy',
            'showLabels',
            'showLocations',
            'simpleMarkersEnabled',
            'tileProviders',
        ]),
        ...mapGetters('auth', ['hasTachographAccess']),
        ...mapGetters('map', ['activeLayer']),
        ...mapGetters('tracker', ['isTrackerCountHigh']),
        controls() {
            return {
                clustering: {
                    label: this.$t('displayOptionsClustering'),
                    value: this.clusteringEnabled,
                    action: this.switchClusteringEnabled,
                },
                ...(this.isTrackerCountHigh && {
                    simpleMarkers: {
                        label: this.$t('displayOptionsSimpleMarkers'),
                        value: this.simpleMarkersEnabled,
                        action: this.switchSimpleMarkersEnabled,
                    },
                }),
                ...(!this.simpleMarkersEnabled && {
                    labels: {
                        label: this.$t('displayOptionsLabels'),
                        value: this.showLabels,
                        action: this.switchShowLabels,
                    },
                    accuracy: {
                        label: this.$t('displayOptionsAccuracy'),
                        value: this.showAccuracy,
                        action: this.switchShowAccuracy,
                    },
                }),
                locations: {
                    label: this.$t('displayOptionsLocations'),
                    value: this.showLocations,
                    action: this.switchShowLocations,
                },
                ...(this.isTrackerCountHigh && {
                    liveUpdates: {
                        label: this.$t('displayOptionsLiveUpdates'),
                        value: this.liveUpdatesEnabled,
                        action: this.switchLiveUpdates,
                    },
                }),
            }
        },
        labelControls() {
            return {
                driverName: {
                    label: this.$t('labelOptionsDriverName'),
                    value: this.displayDriverName,
                    action: this.switchDisplayDriverName,
                },
                remainingTime: {
                    label: this.$t('labelOptionsRemainingTime'),
                    value: this.displayRemainingTime,
                    action: this.switchDisplayRemainingTime,
                },
            }
        },
        isZoomInDisabled() {
            return this.mapInstance.getZoom() >= this.mapInstance.getMaxZoom()
        },
        isZoomOutDisabled() {
            return this.mapInstance.getZoom() <= this.mapInstance.getMinZoom()
        },
        iconSize() {
            return this.mobile ? 24 : 18
        },
    },
    beforeDestroy() {
        this.dropdownHidingListener?.()
    },
    methods: {
        ...mapMutations('map', [
            'setActiveLayerId',
            'setPositionTrackingEnabled',
            'switchClusteringEnabled',
            'switchDisplayDriverName',
            'switchDisplayRemainingTime',
            'switchLiveUpdates',
            'switchShowAccuracy',
            'switchShowLabels',
            'switchShowLocations',
            'switchSimpleMarkersEnabled',
        ]),
        handleTileProviderChange(provider) {
            if (this.activeLayer.layer) {
                this.mapInstance.removeLayer(this.activeLayer.layer)
            }

            this.mapInstance.addLayer(provider.layer)
            this.setActiveLayerId(provider.id)
        },
        toggleDropdown() {
            if (this.isDropdownVisible) {
                return
            }

            this.isDropdownVisible = true

            this.dropdownHidingListener = () => {
                document.body.removeEventListener(
                    'click',
                    this.dropdownHidingListener
                )
                this.isDropdownVisible = false
            }

            setTimeout(() => {
                document.body.addEventListener(
                    'click',
                    this.dropdownHidingListener
                )
            })
        },
        toggleLocationTracking() {
            if (!this.positionTrackingEnabled) {
                this.mapInstance.locate({ watch: true, setView: true })
                this.setPositionTrackingEnabled(true)
            } else {
                this.mapInstance.stopLocate()
                this.setPositionTrackingEnabled(false)
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "displayOptions": "Display",
        "displayOptionsAccuracy": "GPS Accuracy",
        "displayOptionsClustering": "Clustering",
        "displayOptionsLabels": "Labels",
        "displayOptionsLiveUpdates": "Live updates",
        "displayOptionsLocations": "Locations",
        "displayOptionsSimpleMarkers": "Simple markers",
        "labelOptions": "Labels",
        "labelOptionsDriverName": "Driver name",
        "labelOptionsRemainingTime": "Driving time",
        "layerOptions": "Layer",
        "layerOptions-outdoor": "Terrain map",
        "layerOptions-satellite": "Satellite map",
        "layerOptions-standard": "Standard map",
        "layerOptions-street": "Street map",
        "layerOptions-swisstopo": "Swisstopo map",
        "layerOptions-swisstopo-hiking": "Swisstopo hiking map",
        "layerOptions-vectormap": "Light map",
        "layerOptions-vectormap-dark": "Dark map"
    },
    "de": {
        "displayOptions": "Anzeigeoptionen",
        "displayOptionsAccuracy": "GPS Genauigkeit",
        "displayOptionsClustering": "Gruppieren",
        "displayOptionsLabels": "Beschriftungen",
        "displayOptionsLiveUpdates": "Live-Updates",
        "displayOptionsLocations": "Standorte",
        "displayOptionsSimpleMarkers": "Einfache Marker",
        "labelOptions": "Beschriftungen",
        "labelOptionsDriverName": "Fahrende-Name",
        "labelOptionsRemainingTime": "Lenkzeit",
        "layerOptions": "Karte",
        "layerOptions-outdoor": "Geländekarte",
        "layerOptions-satellite": "Satellitenkarte",
        "layerOptions-standard": "Standardkarte",
        "layerOptions-street": "Strassenkarte",
        "layerOptions-swisstopo": "Swisstopo Karte",
        "layerOptions-swisstopo-hiking": "Swisstopo Wanderkarte",
        "layerOptions-vectormap": "Light map",
        "layerOptions-vectormap-dark": "Dark map"
    },
    "fr": {
        "displayOptions": "Affichage",
        "displayOptionsAccuracy": "Précision GPS",
        "displayOptionsClustering": "Regroupement",
        "displayOptionsLabels": "Annotations",
        "displayOptionsLiveUpdates": "Mise à jour en direct",
        "displayOptionsLocations": "Emplacements",
        "displayOptionsSimpleMarkers": "Marqueurs simples",
        "labelOptions": "Annotations",
        "labelOptionsDriverName": "Nom du conducteur",
        "labelOptionsRemainingTime": "Temps de conduite",
        "layerOptions": "Layer",
        "layerOptions-outdoor": "Carte du terrain",
        "layerOptions-satellite": "Carte satellite",
        "layerOptions-standard": "Carte standard",
        "layerOptions-street": "Carte des rues",
        "layerOptions-swisstopo": "Carte Swisstopo",
        "layerOptions-swisstopo-hiking": "Carte d'excursions Swisstopo",
        "layerOptions-vectormap": "Carte claire",
        "layerOptions-vectormap-dark": "Carte sombre"
    },
    "it": {
        "displayOptions": "Display",
        "displayOptionsAccuracy": "GPS Precisione",
        "displayOptionsClustering": "Raggruppamento",
        "displayOptionsLabels": "Etichetta",
        "displayOptionsLiveUpdates": "Aggiornamenti live",
        "displayOptionsLocations": "Locatione",
        "displayOptionsSimpleMarkers": "Marcatori semplici",
        "labelOptions": "Etichetta",
        "labelOptionsDriverName": "Nome del conducente",
        "labelOptionsRemainingTime": "Tempo di guida",
        "layerOptions": "Layer",
        "layerOptions-outdoor": "Mappa dei terreni",
        "layerOptions-satellite": "Mappa satellitare",
        "layerOptions-standard": "Mappa standard",
        "layerOptions-street": "Mappa stradale",
        "layerOptions-swisstopo": "Mappa Swisstopo",
        "layerOptions-swisstopo-hiking": "Mappa escursionistica Swisstopo",
        "layerOptions-vectormap": "Lightstre map",
        "layerOptions-vectormap-dark": "Scura map"
    }
}
</i18n>

<style lang="scss" scoped>
.map-controls {
    $self: &;
    display: flex;
    justify-content: space-between;

    & > div {
        display: flex;

        &:not(:first-child) {
            margin-left: 8px;
        }

        &:nth-child(2) {
            flex-basis: 30%;
        }

        & > * {
            &:not(:first-child) {
                margin-left: 8px;
            }
        }

        // TEMP:
        pointer-events: auto;
    }

    &--mobile {
        justify-content: flex-end;

        button {
            $color: var(--toolbar-font-color, $color-primary-contrast);

            margin-left: 1rem;
            padding: 1rem 0;
            background-color: transparent;
            box-shadow: none;
            color: $color;

            &.active:not(:hover) {
                color: $color;
            }

            &:hover {
                color: $color;
            }
        }

        #{$self}__dropdown {
            max-height: calc(100vh - 70px);
        }
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        margin: 6px 0 12px;
        padding: 0 1rem 1rem;
        max-width: 75vw;
        max-height: calc(100vh - 116px - 2rem);
        background-color: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        color: $color-primary;
        text-align: left;
        overflow-y: auto;
        z-index: 100;
        cursor: default;

        section {
            &:not(:first-child) {
                margin-top: 0.25rem;
            }
        }

        h3 {
            position: sticky;
            top: 0;
            padding-top: 1rem;
            background-color: #fff;
            font-weight: 700;
            font-size: 14px;
            transform: translateY(0);
            transition: transform 0.2s;
            z-index: 100;

            & + * {
                margin-top: 1rem;
            }
        }

        label {
            cursor: pointer;
        }
    }
}

button {
    position: relative;
    display: inline-block;
    padding: 10px 16px;
    background-color: #fff;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    line-height: 20px;
    font-family: 'IBM Plex Sans';
    font-weight: 300;
    font-size: 24px;
    color: $color-primary-lighter;
    transition: all 0.1s;
    outline: none;
    cursor: pointer;

    &.active:not(:hover) {
        color: darken($color-primary-lighter, 30%);
    }

    &:hover {
        color: darken($color-primary-lighter, 15%);
    }

    &:disabled {
        color: rgba($color-primary-lighter, 0.5);
        background-color: rgba(255, 255, 255, 0.5);
        cursor: default;
    }
}

.button-group {
    display: inline-block;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    overflow: hidden;

    button {
        border-radius: 0;
        box-shadow: none;

        &:not(:first-child) {
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}

.dropdown {
    &-enter-active,
    &-leave-active {
        transition: all 0.1s ease-out;
    }

    &-enter,
    &-leave-to {
        transform: translateY(-8px);
        opacity: 0;
    }
}
</style>
