import Vue from 'vue'

import { httpHelper, languageHelper } from '@/utils'

const state = {
    activeTrackerOnMap: null,
    activeTrackerTrace: [],
    assetHistory: null,
    assetHistoryLocationId: null,
    assetHistoryPoint: null,
    assetHistoryPointProposed: null,
    assetTypes: [],
    filterParams: null,
    filteredTrackerIds: null,
    shouldAdjustMapOnFilterChange: false,
    shouldFollowActiveTrackerUpdates: true,
    trackers: [],
}

const getters = {
    activeInLast6HoursAssets(state) {
        const now = new Date()
        return state.trackers.filter(
            ({ last_message_timestamp }) =>
                now - new Date(last_message_timestamp) < 1000 * 60 * 60 * 6
        )
    },
    alertAssets(state) {
        return state.trackers.filter(tracker => tracker.asset_details.alert)
    },
    assetMeasurementLabels(state) {
        const lang = languageHelper.getUsersLanguage()
        return state.assetTypes.reduce((acc, cur) => {
            Object.entries(cur.measurements?.items || {}).forEach(
                ([key, value]) => {
                    const customKey = `assetMeasurement:${cur.id}:${key}`
                    const label = value.props?.label?.[lang] || key
                    acc[customKey] = `${label} (${cur.name})`
                }
            )
            return acc
        }, {})
    },
    assetTags(state) {
        return state.trackers.reduce((acc, cur) => {
            const tags = []

            if (cur.last_gps_measurement) {
                if (cur.last_gps_measurement.kind == 'indoor') {
                    tags.push({
                        label: 'beaconPosition',
                        className: 'list-item__tag orange',
                    })
                }

                if (cur.last_gps_measurement.is_outside) {
                    tags.push({
                        label: 'leftZone',
                        className: 'list-item__tag',
                    })
                }
            }

            if (!cur.active) {
                tags.push({
                    label: 'inactive',
                    className: 'list-item__tag',
                })
            }

            if (cur.asset_details.maintenance_due) {
                tags.push({
                    label: 'maintenance',
                    className: 'list-item__tag',
                })
            }

            return tags.length ? { ...acc, [cur.id]: tags } : acc
        }, {})
    },
    assetTypeIcons(state) {
        return state.assetTypes.reduce((acc, cur) => {
            acc[cur.identifier] = cur.marker.url
            return acc
        }, {})
    },
    assetTypesById(state) {
        return state.assetTypes.reduce((acc, cur) => {
            acc[cur.id] = cur
            return acc
        }, {})
    },
    assetsById(state) {
        return state.trackers.reduce((acc, cur) => {
            acc[cur.id] = cur
            return acc
        }, {})
    },
    assetsByType: state => assets => {
        const trackers = assets
            ? state.trackers.filter(tracker => assets.includes(tracker.asset))
            : state.trackers

        return trackers
            .map(tracker => tracker.asset_details.asset_type_identifier)
            .sort((a, b) => a.localeCompare(b))
            .reduce((acc, cur) => {
                acc[cur] = acc[cur] ? acc[cur] + 1 : 1
                return acc
            }, {})
    },
    assetsInLocation: state => locationId => {
        return state.trackers.filter(tracker => tracker.location == locationId)
    },
    customerFieldLabels(state) {
        const lang = languageHelper.getUsersLanguage()
        return state.assetTypes.reduce((acc, cur) => {
            Object.entries(
                cur.additional_data_schema?.properties || {}
            ).forEach(([key, value]) => {
                const customKey = `customerField:${cur.id}:${key}`
                const label = value.label?.[lang] || key
                acc[customKey] = `${label} (${cur.name})`
            })
            return acc
        }, {})
    },
    fullBinAssets: state => (threshold = 0.8, height = 1.2) => {
        return state.trackers.filter(
            tracker =>
                tracker.asset_details.asset_type_type === 'bin' &&
                height - tracker.asset_details.sensor_data.distance.value >=
                    height * threshold
        )
    },
    isTrackerCountHigh(state) {
        return state.trackers.length >= 500
    },
    lostAssets(state) {
        return state.trackers.filter(
            tracker => tracker.last_gps_measurement?.is_outside
        )
    },
    lowBatteryAssets(state) {
        return state.trackers.filter(
            tracker =>
                tracker.asset_details.sensor_data.battery?.low ||
                tracker.asset_details.sensor_data.battery_status?.value ===
                    'critical'
        )
    },
}

const actions = {
    async loadHistoryData({ commit }, { trackerId, fromDate, toDate }) {
        let url = [
            'measurements/?',
            'tracker=' + trackerId,
            '&timestamp_min=' + encodeURIComponent(fromDate),
            '&timestamp_max=' + encodeURIComponent(toDate),
            '&limit=' + process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST,
        ].join('')
        let measurements = []
        while (url) {
            const { data } = await httpHelper.get(url)
            measurements = Object.freeze(measurements.concat(data.results))
            url = data.next
        }
        commit('setAssetHistory', measurements)
    },
}

const mutations = {
    resetAssetHistory(state) {
        state.assetHistory = null
    },
    setActiveTrackerOnMap(state, data) {
        if (!data) {
            state.activeTrackerTrace = []
        } else if (data.id !== state.activeTrackerOnMap?.id) {
            const { latitude, longitude } = data.asset_details.position
            state.activeTrackerTrace = [[latitude, longitude]]
        }

        state.activeTrackerOnMap = data
    },
    setAssetHistory(state, data) {
        state.assetHistory = Object.freeze(data)
    },
    setAssetHistoryLocationId(state, data) {
        state.assetHistoryLocationId = data
    },
    setAssetHistoryPoint(state, data) {
        state.assetHistoryPoint = data
    },
    setAssetHistoryPointProposed(state, data) {
        state.assetHistoryPointProposed = data
    },
    setFilterParams(state, data) {
        state.filterParams = data
    },
    setFilteredTrackerIds(state, data) {
        state.filteredTrackerIds = data
    },
    setShouldAdjustMapOnFilterChange(state, data) {
        state.shouldAdjustMapOnFilterChange = data
    },
    setShouldFollowActiveTrackerUpdates(state, data) {
        state.shouldFollowActiveTrackerUpdates = data
    },
    updateAsset(state, { id, ...data }) {
        const index = state.trackers.findIndex(tracker => tracker.asset === id)
        const tracker = state.trackers[index]

        if (!tracker) {
            return
        }

        Object.keys(data).forEach(key => {
            tracker.asset_details[key] = data[key]
        })

        Vue.set(state.trackers, index, tracker)
    },
    updateTracker(state, { id, ...data }) {
        const index = state.trackers.findIndex(tracker => tracker.id === id)
        const tracker = state.trackers[index]

        if (!tracker) {
            return
        }

        Object.keys(data).forEach(key => {
            tracker[key] = data[key]
        })

        Vue.set(state.trackers, index, tracker)
    },
    updateTrackerLastContact(state, { id, time, alert }) {
        const index = state.trackers.findIndex(tracker => tracker.id === id)
        const tracker = state.trackers[index]

        if (!tracker) {
            return
        }

        tracker.asset_details.alert = alert
        tracker.last_message_timestamp = time

        Vue.set(state.trackers, index, tracker)
    },
    updateTrackerPosition(state, { id, lat, lng, ...data }) {
        const index = state.trackers.findIndex(tracker => tracker.id === id)
        const tracker = state.trackers[index]

        if (!tracker) {
            return
        }

        tracker.asset_details.current_locations = data.currentLocations
        tracker.asset_details.position.latitude = lat
        tracker.asset_details.position.longitude = lng

        tracker.last_gps_measurement = {
            ...tracker.last_gps_measurement,
            lat,
            lng,
            is_outside: data.isOutside,
            timestamp: data.time,
        }

        if (state.activeTrackerOnMap?.id === id) {
            state.activeTrackerTrace = [[lat, lng], ...state.activeTrackerTrace]
        }

        Vue.set(state.trackers, index, tracker)
    },
    updateTrackerSensorData(state, { id, values, time }) {
        const index = state.trackers.findIndex(tracker => tracker.id === id)
        const tracker = state.trackers[index]

        if (!tracker) {
            return
        }

        Object.entries(values).forEach(([key, value]) => {
            tracker.asset_details.sensor_data[key] = {
                value,
                last_update: time,
            }
        })

        if (values.battery) {
            const threshold = this.getters['auth/batteryThreshold']
            const isBatteryLow = Math.floor(values.battery) <= threshold

            tracker.asset_details.sensor_data.battery.low = isBatteryLow
            tracker.tracker_status = {
                ...tracker.tracker_status,
                battery: Math.floor(values.battery),
                battery_low: isBatteryLow,
                last_update: time,
            }
        }

        Vue.set(state.trackers, index, tracker)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
